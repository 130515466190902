import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../components/floating-button/floating-button';
import Layout from '../../../components/layout';
import React from 'react';

const PressKitPage = (props) => {
    const components = props.data.cms.pressKitPageTemplate?.components || null;
    const mediaContact = props.data.cms.mediaContactTemplate;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} mediaContact={mediaContact} />
        </Layout>
    );
};

export default PressKitPage;

export const query = graphql`
query ($locale: String!){
  cms {
    mediaContactTemplate(locale: $locale) {
      newMediaContact {
        mediaContactEmails
        mediaContactTitle
        mediaContactFollowUsLabel
        mediaContactSocialsLinks {
          mediaContactSocialLink
          mediaContactSocialLinkIcon {
            url
          }
        }
      }
    }
    pressKitPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          title
          headerSubtitle: subtitle
          textBoxCustomClasses
          backgroundImage {
            url
          }
          backgroundTopOffset
          backgroundImageHeight
          contentOffset
          mobileContentOffset
        }
        ... on STRAPI_ComponentEmployeesListBoardList {
          __typename
          topPusherHeightMobile
          topPusherHeight
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          employee {
            employee {
              bio
              image {
                url
              }
              city
              nameAndSurname
              title
            }
          }
        }
        ... on STRAPI_ComponentComponentsTextWithMediaContact {
          __typename
          text
        }
        ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
          __typename
          sectionTitle {
            description
            style
            tailwindClasses
            title
            titleIcon {
              url
            }
            ytLink
          }
          tabHeight
          topPusherHeight
          topPusherHeightMobile
          tabs {
            brochuresToDownload {
              url
            }
            imagesMetadata
            images {
                url
            }
            label
            type
          }
        }
        ... on STRAPI_ComponentComponentsSpelling {
          __typename
          sectionTitle {
            ytLink
            titleIcon {
              url
            }
            title
            tailwindClasses
            style
            description
          }
          topPusherHeight
          topPusherHeightMobile
          spellingItem {
            bigText
            description
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
